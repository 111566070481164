import Error from "@@/common/Error.vue";
import domain from "@/service/domain";
import { backProductList, bullProductList } from "@api/index";
import { mapState } from "vuex";
import CenterList from "@@/home/centerList.vue";
import { env } from "md-base-tools/env";
// import header from "md-base-tools/header";
export default {
  components: {
    CenterList,
    Error
  },
  computed: {
    ...mapState({
      linkUrl: state => state.user.linkUrl,
      userIcon: state => state.user.userIcon,
      userId: state => state.user.userId,
      platform: state => state.user.platform
    }),
    ifOwner() {
      return this.userId && this.owner && this.owner.id && this.userId == this.owner.id ? true : false;
    },
    ifWxApp() {
      return env.isWxApp();
    }
  },
  data() {
    return {
      loading: true,
      is_next: true,
      isShow: true,
      detail: {},
      owner: {},
      file: {},
      pall: false,
      showError: false,
      authInfoType: false,
      orderListShow: false,
      message: "",
      op_type: 0,
      nft_num: 0,
      stock_hash: "",
      receive_code: "",
      status: "",
      status_name: "",
      user_info: {},
      orderData: {},
      productList: [],
      toUserid: "",
      page: 1,
      type: "" // 2: 创意
    };
  },
  async mounted() {
    this.toUserid = this.$route.query.user_id;
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    document.title = '支持的项目';
    // eslint-disable-next-line no-undef
    userHeader.titleText(this.type == 2 ? "看好的创意" : "支持的项目");
    await this.myFavorsProductList();
  },
  methods: {
    clickpall() {
      this.pall = !this.pall;
    },
    toOrder(type) {
      location.href = `${domain.wap}/order/order_list?nav=${type}`;
    },
    toEvaluate() {
      location.href = `${domain.wap}/product/evaluate/list`;
    },
    async getNext(e) {
      if (e === 1) {
        this.productList = [];
      }
      this.page = e;
      if (this.is_next || e === 1) {
        await this.myFavorsProductList();
      }
    },
    async myFavorsProductList() {
      this.loading = true;
      const {
        status,
        data
      } = await (this.type == 2 ? bullProductList : backProductList)({
        // user_id: this.toUserid || this.userId,
        to_user_id: this.toUserid || this.userId,
        page: this.page,
        page_size: 10
      });
      this.loading = false;
      if (status == 0) {
        this.is_next = data.is_next;
        this.productList.push(...data.list);
      }
    }
  }
};